.thank-you-product-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--product-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .image-container {
            border-radius: 16px;
            background-color: #cfcfcf;
            height: 80px;
            width: 80px;
            display: flex;

            img {
                margin: auto;
                height: 60px;
                max-width: 80px;
                overflow: hidden;
            }
        }

        .info-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;

            .name {
                font-family: "Roboto";
                font-size: 18px;
                font-weight: 500;
                color: #1d1d1d;
            }

            .color {
                font-family: "Roboto";
                font-size: 14px;
                color: #989898;
            }
        }

        .price-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-end;

            .old-price {
                font-family: "Roboto";
                font-size: 14px;
                color: #717171;
            }

            .price {
                font-family: "Roboto";
                font-size: 24px;
                font-weight: 500;
                color: #1d1d1d;
            }
        }
    }
}

@media (max-width: 768px) {
    .thank-you-product-item {
        &--product-info {
            align-items: flex-start;

            .price-container {
                display: none;
            }
        }
    }
}