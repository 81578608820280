button{
    cursor: pointer!important;
}
button:disabled {
    opacity: 0.8;
    cursor: default!important;
}

.check-out {
    background-color: #f6f6f6;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    padding: 32px 0;
    gap: 32px;
    font-family: "Roboto";
    color: #313131;

    .container {
        max-width: 420px;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .email-form {
            width: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 22px;
            background-color: #fff;
            border: 1px solid #e4e4e4;

            h2 {
                color: inherit;
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 16px;
            }

            button {
                background-color: #0c0c0c;
                border-radius: 32px;
                padding: 16px 48px;
                font-weight: 500;
                color: #FFF;
                font-family: "Poppins";
                width: 100%;

                &:hover {
                    background-color: #383838;
                }
            }

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                h2 {
                    width: 100%;
                }

                button {
                    display: none;
                    width: auto;
                }
            }

            p {

                color: #767676;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 22px;
            }

            span {
                display: none;
            }

            &.active {
                span {
                    display: block;
                    word-wrap: break-word;
                    color: #313131;
                    font-size: 14px;
                    line-height: 22px;
                }

                p,
                button,
                input {
                    display: none;
                }

                div {
                    button {
                        display: block;
                        padding: 4px 8px;
                        color: #a1a1a1;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        text-transform: none;
                        background-color: transparent;
                        border: none;
                    }
                }
            }
        }

        .delivery-form {
            width: 100%;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 22px;
            background-color: #fff;
            border: 1px solid #e4e4e4;


            .editable-content {
                display: flex;
                flex-direction: column;
                width: 100%;

                .delivery-option {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    color: rgb(14, 14, 14);
                    text-rendering: optimizelegibility;
                    -webkit-font-smoothing: antialiased;
                    font-family: "Roboto";
                    padding: 16px;
                    margin-bottom: 22px;
                    border: 1px solid rgb(231, 231, 231);
                    border-radius: 4px;

                    label {
                        margin-bottom: 6px;
                        font-size: 12px;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: 0.75px;
                        line-height: 16px;
                    }

                    div {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }

            button {
                margin-top: 22px;
                background-color: #0c0c0c;
                border-radius: 32px;
                padding: 16px 48px;
                font-weight: 500;
                color: #FFF;
                font-family: "Poppins";
                width: 100%;

                &:hover {
                    background-color: #383838;
                }
            }

            .title {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;

                h2 {
                    color: inherit;
                    font-size: 22px;
                    font-weight: 500;
                    margin-bottom: 24px;
                    width: 100%;
                }

                button {
                    width: auto;
                    margin: 0;
                    display: none;
                }
            }

            .content {
                display: none;

                label {
                    font-weight: 500;
                    color: rgb(14, 14, 14);
                    font-size: 14px;
                    box-sizing: border-box;
                    text-rendering: optimizelegibility;
                    -webkit-font-smoothing: antialiased;
                    font-family: "Roboto";
                    text-transform: uppercase;
                    display: block;
                    letter-spacing: 0.75px;
                    line-height: 24px;
                }

                &--container {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;

                    .info {
                        display: flex;
                        flex-direction: column;
                        word-wrap: break-word;
                        max-width: 187px;
                        text-rendering: optimizelegibility;
                        -webkit-font-smoothing: antialiased;
                        font-family: "Roboto";

                        span {
                            margin: 0px;
                            padding: 0px;
                            font-weight: 500;
                            box-sizing: border-box;
                            color: rgb(14, 14, 14);
                            font-size: 14px;
                            line-height: 22px;
                        }

                        .placeholder {
                            display: flex;
                            flex-direction: column;
                            margin: 0px;
                            padding: 0px;
                            color: rgb(102, 102, 102);
                            font-weight: 400;

                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    .shipping {
                        display: flex;
                        flex-direction: column;
                        color: rgb(14, 14, 14);
                        text-rendering: optimizelegibility;
                        -webkit-font-smoothing: antialiased;
                        font-family: "Roboto";

                        p {
                            margin: 0px;
                            padding: 0px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 22px;
                        }

                        span {
                            font-size: 14px;
                            line-height: 22px;
                            font-weight: 400;
                        }
                    }
                }
            }

            &.active {

                .editable-content {
                    display: none;
                }

                button,
                input {
                    display: none;
                }

                .form {
                    display: none;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                }

                .title {
                    button {
                        display: block;
                        padding: 4px 8px;
                        color: #a1a1a1;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        text-transform: none;
                        background-color: transparent;
                        border: none;
                    }
                }

            }

            &.disabled {
                background-color: #fcfcfc;
                font-weight: 300;
                color: #878787;
                font-size: 22px;
                line-height: 22px;
                max-height: 69px;
                overflow: hidden;
            }

        }
    }

    .order-summary {
        max-width: 420px;
        width: 100%;
        background-color: #FFF;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 22px;
        background-color: #fff;
        border: 1px solid #e4e4e4;

        h2 {
            color: #313131;
            font-size: 22px;
            font-weight: 500;
            font-family: "Roboto";
            font-size: 23px;
            margin-top: 2px;
        }

        .subtotal-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            word-wrap: normal;
            color: #767676;
            font-size: 14px;
            line-height: 22px;
        }


        .total-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            word-wrap: normal;
            color: #313131;
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            margin-top: 11px;
        }

        .secure-badge {
            padding-top: 27px;
            border-top: 1px solid #e4e4e4;
            color: #0e0e0e;
            display: flex;
            font-size: 9.75px;
            justify-content: center;
            letter-spacing: .75px;
            line-height: 22px;
            text-transform: uppercase;

            &::before {
                background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='22' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20 15.928-8.25 4.916L3 15.912V2.471C8.489.51 14.994.517 20 2.45v13.478Z' stroke='%23000' stroke-width='2'/%3E%3Cpath d='m7 10 3 3 6-6' stroke='%23000' stroke-width='2'/%3E%3C/svg%3E") no-repeat;
                content: "";
                height: 22px;
                margin-right: 16px;
                width: 22px;
            }
        }

    }

}

@media (max-width: 768px) {
    .check-out {
        flex-direction: column-reverse;
    }
}