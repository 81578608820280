input,
select {
  margin-bottom: 8px!important;
  padding: 8px!important;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &.selected {
    color: #495057;
  }

  &.placeholder {
    color: #999;
  }
}

body {
  background-color: #f6f6f6;
}

.App {
  width: 100%;
  height: 100%;
}


@media (max-width: 768px) {
  .App {
      height: auto;
  }
}