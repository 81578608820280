.order-summary-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    &--product-info {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        .image-container {
            border-radius: 16px;
            background-color: #cfcfcf;
            height: 70px;
            width: 70px;
            display: flex;

            img {
                margin: auto;
                height: 60px;
                max-width: 60px;
                overflow: hidden;
            }
        }

        .info-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: flex-start;

            .name {
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: "Poppins";
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: #1d1d1d;
            }

            .text {
                font-family: "Roboto";
                color: #767676;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .price-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-end;

            .old-price {
                font-family: "Roboto";
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: #717171;
            }

            .price {
                font-family: "Roboto";
                color: #313131;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
            }
        }
    }
}