.NotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; // Utiliza todo el alto de la pantalla
    background-color: #f5f5f5;
    color: #333;

    .NotFound-container {
        text-align: center;

        h1 {
            font-size: 6rem;
            font-weight: bold;
            color: #de354c;
        }

        p {
            font-size: 1.5rem;
            margin-top: 0.5rem;
            color: #555;
        }

        .home-link {
            display: inline-block;
            margin-top: 20px;
            padding: 10px 20px;
            background-color: #4a69bd;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s;

            &:hover {
                background-color: #1e3799;
            }
        }
    }
}