.product-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 96px;

    .title {
        font-family: 'Rubik';
        font-weight: bold;
        color: #0c0c0c;
        font-size: 48px;
        // font-weight: 600;
        margin-bottom: 32px;
    }

    .price-discount {
        font-family: "Poppins";
        color: #8a8b8a;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .price {
        font-family: "Poppins";
        color: #0d0d0d;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 32px;
    }

    .description {
        font-family: "Roboto";
        color: #a2a3a2;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 48px;
    }
}