.accordion-item {
    .accordion-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent;
        color: #8a8b8a;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: background-color 0.4s;
        border-bottom: 1px solid #ccc;
        font-family: "Poppins";

        &:hover,
        &.active {
            color: #3c3d3c;
            background-color: transparent;
        }
    }

    .accordion-content {
        font-family: "Poppins";
        padding: 0 16px;
        background-color: transparent;
        overflow: hidden;
        transition: max-height 0.4s ease, padding 0.4s ease;
        font-size: 14px;
        line-height: 20px;
        text-align: justify;

        &.active {
            max-height: 1000px;
            padding: 24px 16px;
        }
    }
}