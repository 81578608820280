.add-to-cart {
    background-color: #0c0c0c;
    border-radius: 32px;
    padding: 16px 48px;
    font-weight: 500;
    color: #FFF;

    &:hover {
        background-color: #383838;
    }
}

@media (max-width: 768px) {
    .add-to-cart {
        margin: auto;
    }
}