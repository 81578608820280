.cart {
    background-color: #FFF;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 24px;
    
    &--container {
        width: 900px;
        .title {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 32px;
            color: #1d1d1d;
            margin-bottom: 16px;
        }

        .order-summary {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .items-container {
                flex: 2;
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;

            }

            .summary-container {
                flex: 1;
            }

        }
    }
}


@media (max-width: 768px) {
    .cart {
        &--container {
            .title {
                text-align: center;
            }
            .order-summary {
                flex-direction: column-reverse;
            }
        }
    }

}