.carousel {
    position: relative;
    max-width: 600px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &--background {
        margin: auto;
        background-color: #acbcbf;
        width: 300px;
        height: 550px;
        border-bottom-left-radius: 200px;
        border-bottom-right-radius: 200px;
        position: absolute;
        z-index: -1;
    }

    .carousel-slide {
        position: relative;
    }

    .fade {
        opacity: 0;
    }

    .carousel-image {
        width: 70%;
        transition: opacity 0.5s ease;
        opacity: 1;
    }

    .carousel-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        z-index: 2;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}