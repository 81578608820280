.cart-item {
    border: #eaeaea solid 1px;
    border-radius: 12px;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;

    .brand {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #1d1d1d;
        margin-bottom: 8px;
    }

    .price-container-xs {
        display: none;
    }

    &--product-info {
        display: flex;
        flex-direction: row;
        gap: 24px;

        .image-container {
            border-radius: 16px;
            background-color: #cfcfcf;
            height: 120px;
            width: 120px;
            display: flex;

            img {
                margin: auto;
                height: 80px;
                max-width: 80px;
                overflow: hidden;
            }
        }

        .info-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;

            .category {
                font-family: "Roboto";
                font-size: 14px;
                padding: 8px 16px;
                border: solid 1px #e2e2e2;
                border-radius: 16px;
            }

            .name {
                font-family: "Poppins";
                font-size: 20px;
                color: #1d1d1d;
            }

            .color {
                font-family: "Roboto";
                font-size: 14px;
                color: #989898;

                span {
                    color: #292d32;
                }
            }
        }



        .price-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: flex-end;

            .old-price {
                font-family: "Roboto";
                font-size: 16px;
                color: #717171;
            }

            .price {
                font-family: "Roboto";
                font-size: 24px;
                color: #1d1d1d;
            }

            .controls {
                background-color: #f9f9f9;
                display: flex;
                flex-direction: row;
                border-radius: 8px;
                border: solid 1px #d7d7d7;
                align-items: center;
                gap: 16px;

                button {
                    padding: 8px 16px;
                    border: none;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .cart-item {

        &--product-info {
            .info-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 16px;
                align-items: flex-start;

                .category {
                    font-size: 12px;
                }

                .name {
                    font-size: 18px;
                }

                .color {
                    font-size: 14px;

                }
            }

            .price-container {
                display: none;
            }
        }

        .price-container-xs {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;

            .price {
                font-size: 24px;
            }

            .controls {
                background-color: #f9f9f9;
                display: flex;
                flex-direction: row;
                border-radius: 8px;
                border: solid 1px #d7d7d7;
                align-items: center;
                gap: 16px;

                button {
                    padding: 8px 16px;
                    border: none;
                }
            }
        }
    }

}