.header {
    width: 100%;
    max-height: 56px;
    overflow: hidden;
    height: 56px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        margin: auto;
        height: 48px;
    }
}