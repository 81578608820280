.thank-you {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    align-items: center;

    .container {
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 22px;
        background-color: #fff;
        border: 1px solid #e4e4e4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: 680px;
        gap: 16px;
        font-family: "Roboto";
        color: #404040;

        p {
            text-align: center;
            font-weight: 400;
            font-size: 16px;
        }

        h2 {
            font-weight: 600;
            font-size: 24px;
        }

        img {
            height: 150px;
        }

        .status-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #FFF;
            background-color: #019557;
            height: 64px;
            width: 64px;
            border-radius: 50%;
            right: 24px;
            top: 24px;
            font-size: 24px;
        }
    }

    .order-detail {
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 22px;
        background-color: #fff;
        border: 1px solid #e4e4e4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: 680px;
        gap: 16px;
        font-family: "Roboto";
        color: #404040;

        .order-status-list {
            align-items: center;
            width: 100%;
            display: flex;
            list-style: none;

            li {
                align-items: center;
                display: flex;

                &:not(:last-child) {
                    width: 100%;
                }

                span {
                    font-size: 12px;
                    border-radius: 8px;
                    background-color: #efefef;
                    padding: 4px 16px;
                    color: #959595;
                }

                &:not(:last-child)::after {
                    content: "";
                    display: inline-block;
                    border-color: #efefef;
                    border-bottom-width: 2px;
                    width: 100%;
                    height: 0.25rem;
                }

                &.active {
                    span {
                        background-color: #019556;
                        color: #FFF;
                        font-weight: 500;
                    }

                    &::after {
                        content: "";
                        border-color: #019556;
                    }
                }
            }
        }

        .order-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 16px;

            .shipping {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-family: "Roboto";
                color: #2b2b2b;
                font-size: 14px;
                line-height: 18px;

                h2 {
                    font-size: 16px;
                    text-transform: none;
                    font-weight: 600;
                    margin-bottom: 32px;
                }

                p,
                span {
                    text-transform: uppercase;
                    color: #acacac;
                }

                .black {
                    font-weight: 500;
                    text-transform: none;
                    color: #2b2b2b;
                }
            }

            .payment-method {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-family: "Roboto";
                font-family: "Roboto";
                color: #2b2b2b;
                font-size: 14px;
                line-height: 18px;

                h2 {
                    font-size: 16px;
                    text-transform: none;
                    font-weight: 600;
                    margin-bottom: 32px;
                }

                p {
                    font-weight: 600;
                    text-transform: none;
                    color: #2b2b2b;

                    span {
                        font-weight: 400;
                    }
                }
            }

            .order-summary {
                width: 100%;
                display: flex;
                flex-direction: column;
                font-family: "Roboto";
                color: #2b2b2b;

                h2 {
                    font-size: 16px;
                    text-transform: none;
                    font-weight: 600;
                    margin-bottom: 32px;
                }

                .price-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    color: #9d9d9d;
                    margin-bottom: 4px;
                    font-size: 14px;
                }

                .total {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-top: solid 1px #9d9d9d;
                    padding-top: 8px;
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }

    .products {
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 22px;
        background-color: #fff;
        border: 1px solid #e4e4e4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: 680px;
        gap: 16px;
        font-family: "Roboto";
        color: #404040;
    }
}

@media (max-width: 768px) {
    .thank-you {
        .order-detail {
            .order-info {
                width: 100%;
                flex-direction: column;
                gap: 32px;

            }
        }
    }
}