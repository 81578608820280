.cart-summary {
    border: #eaeaea solid 1px;
    border-radius: 12px;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item {
        font-family: "Roboto";
        color: #7e7e7e;
        font-size: 16px;
    }

    .item-value {
        font-family: "Poppins";
        font-size: 18px;
        color: #1d1d1d;
    }

    h2 {
        font-family: "Roboto";
        color: #1d1d1d;
        font-weight: 500;
        font-size: 18px;
    }

    button {
        margin-top: 16px;
        font-family: "Roboto";
        background-color: #0c0c0c;
        border-radius: 32px;
        padding: 16px 48px;
        font-weight: 400;
        color: #FFF;

        &:hover {
            background-color: #383838;
        }
    }

}