.shop {
    max-width: 1280px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFF;

    &-container {
        width: 100%;
        background-color: #FFF;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
    }

    .product-description-container {
        width: 30%;
        padding-right: 48px;
    }

    .product-info-container {
        padding-left: 24px;
        width: 30%
    }

    .product-image-container {
        width: 40%;
    }
}

@media (max-width: 768px) {
    .shop {
        max-width: 100%;

        &-container {
            width: 100%;
            flex-direction: column;

            .product-description-container {
                padding: 16px;
                width: 100%;
            }

            .product-image-container {
                width: 100%;
            }

            .product-info-container {
                padding: 16px;
                width: 100%;
                margin-bottom: 32px;
            }
        }

    }
}